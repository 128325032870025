.bar {
  min-height: 77px;
  display: flex;
  align-items: center;
}

.barMobile {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 20px #d8d8d879;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.menuIcon {
  path {
    fill: #838383 !important;
  }
}

.user {
  position: relative;
  font-size: 16px;
  display: flex;
  gap: 4px;
  align-items: center;

  .iconBackground {
    flex: 0;
    flex-basis: 30px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-bottom: -10px;
    }
  }

  .userLabel {
    flex: 1;
  }
}

.title {
  font-size: 30px;
  line-height: 38px;
  font-weight: normal;
  color: #5a5a5a;
  margin: 22px 0px;
}
