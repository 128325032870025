.table.table-summary {
  font-size: 18px;
  color: $color-dark;
  margin-bottom: 0px;
  text-align: right;
  thead {
    th {
      font-weight: 400;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #c5c5c5;
      td:first-child,
      th:first-child {
        text-align: left;
      }
    }

    tr:last-child {
      border-bottom: none;
    }
  }
  tr td,
  tr,
  th {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
}
