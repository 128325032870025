.box-outlined {
  border: 1px solid $secondary;
  border-radius: 8px;
  padding: 15px;
}

.box-app-version {
  font-size: 10px;
  position: fixed;
  left: 10px;
  bottom: 10px;
  background-color: #fff;
  padding: 3px;
  z-index: 9999;
}

// ---------- CARD --------------
.card {
  border: none;
  border-radius: 4px;
}
// ---------- CARD --------------

.overflow-scroll-hidden {
  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overflow-scroll-hidden::-webkit-scrollbar {
  display: none;
}

.section-box {
  border-radius: 4px;
  padding: 10px;
  margin: 2px 5px;
  box-shadow: 0px 3px 10px #bfbfbf29;
}

.outlined-box-primary {
  border: 1px solid $primary;
  border-radius: 4px;
  padding: 15px 25px;
}

.print-hidden-container {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: -1;
  overflow: hidden;
}
.tc-primary {
  color: $primary;
}

button.anchor-like {
  background: transparent;
  border: 0;
  color: $primary;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: darken($primary, 30%);
  }
}

.box-hover {
  height: 100%;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  flex-direction: initial;
  min-width: 100%;

  &:hover {
    background-color: #f0fced;
    cursor: pointer;
  }
  span {
    width: 100%;
  }
}
