.filtered-table {
  table {
    border: 1px solid $bg-gray-100;

    td {
      border-top: 1px solid $bg-gray-100;
      text-transform: uppercase;
      span {
        float: left;
        position: relative;
      }
      .chevron-icon {
        float: right;
      }
      .information-icon {
        margin-top: 100px;
      }
      .no-filter-title,
      .no-filter-desc {
        text-align: center;
        width: 100%;
        min-width: 100%;
        text-transform: none;
      }
      .no-filter-title {
        font-size: 22px;
        color: $color-dark;
      }
      .no-filter-desc {
        font-size: 18px;
        margin-bottom: 200px;
      }
    }

    th,
    td {
      padding: 10px 25px;
    }
  }
  .g-icon.spin {
    margin-top: 100px;
  }
}
.filtered-table.study-plans {
  span {
    max-width: 80%;
  }
  .chevron-icon {
    max-width: 10%;
  }
}

.filtered-table.courses {
  th {
    border: none;
    text-align: center;
  }
  th,
  td {
    padding: 10px 15px;
    text-align: left;
  }
  .cevron-left {
    margin-bottom: 1px;
  }

  th:nth-child(1),
  td:nth-child(1) {
    div {
      width: 60px !important;
    }
    div.g-searchbox,
    div.form-group,
    div.no-courses-found {
      width: 100% !important;
    }
  }
}

@media (max-width: 767px) {
  .filtered-table.courses {
    th,
    td {
      padding: 10px 10px;
      .cevron-left {
        width: 12px;
      }
    }

    th:nth-child(1),
    td:nth-child(1) {
      div {
        width: 60px !important;
      }
    }
  }
}

.filtered-table.sections {
  td {
    text-transform: none;
  }
  td {
    padding: 0.75rem;
  }
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-12,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-12 {
    padding-left: 9px;
    padding-right: 9px;
  }
}
