.tracking-status-box {
  border: 1px solid $primary;

  &.present-bg {
    background: #cef0da;
  }
  &.absent-bg {
    background: #d101253b;
  }

  &.justified-bg {
    background: #ffe6a1;
  }
}
