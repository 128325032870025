.search-form {
  .g-outlined-select__menu {
    z-index: 9;
  }

  .action-buttons {
    position: relative;
    max-width: 50%;
    float: left;
    width: calc(50% - 5px);
  }

  .action-buttons.search {
    margin-right: 10px;
  }

  .col,
  .col-2 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .clean-button,
  .g-outlined-select > div:nth-child(2) {
    height: 40px;
  }

  label {
    margin-bottom: 0px;
  }

  .clean-button {
    margin-top: 21px;
    width: 100%;
    span {
      font-size: 16px;
    }
  }

  .advanced-search {
    float: right;
    color: $secondary;
    cursor: pointer;
    .chevron {
      float: right;
      margin-top: 6px;
      margin-left: 8px;
    }

    .flipped {
      -moz-transform: scaleY(-1);
      -o-transform: scaleY(-1);
      -webkit-transform: scaleY(-1);
      transform: scaleY(-1);
      filter: FlipV;
      -ms-filter: "FlipV";
    }
  }
}
