$color-status-approved: #adf7a8;
$color-status-pending: #abcce9;
$color-status-failed: #ffa19d;
$color-status-validated: #ee9ef8;
$color-status-enrolled: #fff784;

.g-progression {
  display: flex;
  width: 100%;
  overflow-x: auto;

  .course-container {
    min-width: 98px;
    max-width: 120px;
    min-height: 100px;
    border: 1px solid $color-gray-100;
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 15px;
  }

  .course-name {
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 12px;
    line-height: 15px;
    color: $color-dark;
    margin-bottom: 10px;
  }

  .course-detail {
    font-size: 10px;
  }
}

.g-legend-progression {
  .g-status-container {
    display: flex;
    align-items: center;
  }

  .box-legend {
    width: 30px;
    height: 25px;
    border-radius: 4px;
  }
}
.box-E {
  background-color: $color-status-enrolled;
}
.box-A {
  background-color: $color-status-approved;
}
.box-P {
  background-color: $color-status-pending;
}
.box-R {
  background-color: $color-status-failed;
}
.box-V {
  background-color: $color-status-validated;
}
