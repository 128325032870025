@media (max-width: 768px) {
  .course-list {
    margin-bottom: 25px;
    .table-responsive {
      table {
        th:nth-child(1),
        td:nth-child(1),
        th:nth-child(3),
        td:nth-child(3) {
          display: none;
        }
      }
    }
  }
  .course-details {
    .table-responsive {
      .text-small {
        font-size: 12px;
      }
      .hours-list {
        padding-right: 0;
      }
    }
  }
}

.course-list {
  .table-responsive {
    table {
      th,
      td {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
}
